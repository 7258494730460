export const whitelistedDomains = [
  'help.li.me',
  'github.com',
  'google.com', // Allow Google Maps URLs
  'hyperwallet.com',
];

export const isAbsoluteUrl = (url: string) =>
  /^https?:\/\//.test(url) ||
  /^\/\/[^/]+/.test(url) ||
  /^mailto:|^tel:/.test(url);

export const extractHostname = (url: string): string | null => {
  const match = url.match(/^https:\/\/([^/?#]+)(?:[/?#]|$)/i); // Enforce https:// only
  return match ? match[1] : null;
};

export const isWhitelistedDomain = (url: string) => {
  const hostname = extractHostname(url);
  if (!hostname) {
    return false;
  }

  return whitelistedDomains.some(
    (domain) => hostname === domain || hostname.endsWith(`.${domain}`), // Allow subdomains
  );
};

// Ensure query params (latitude & longitude) are safe
export const isValidLatLong = (url: string): boolean => {
  if (!url.startsWith('https://www.google.com/maps')) {
    return false;
  }

  const coordsMatch = url.match(/[?&]q=(-?\d+\.?\d*),(-?\d+\.?\d*)/);
  if (!coordsMatch) {
    return false;
  }

  const lat = parseFloat(coordsMatch[1]);
  const lng = parseFloat(coordsMatch[2]);

  return (
    !Number.isNaN(lat) &&
    !Number.isNaN(lng) &&
    lat >= -90 &&
    lat <= 90 &&
    lng >= -180 &&
    lng <= 180
  );
};
