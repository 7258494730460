import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { pxToRem } from 'components/theme/typography';
import { APP_NAME, APP_VERSION, GIT_REPO } from 'constants/app';
import { logoutUser } from 'reduxStore/actions/session';
import SafeLink from 'components/SafeLink/SafeLink';

declare const CURRENT_VERSION: string | undefined;

const FooterStyle = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const AppNameStyle = styled(Typography)(({ theme }) => ({
  height: pxToRem(24),
  color: theme.palette.grey[800],
}));

const AppVersionStyle = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    m: 0,
    height: pxToRem(24),
    color: theme.palette.grey[600],
  }),
);

const LogoutButtonStyle = styled(Button)(({ theme }) =>
  theme.unstable_sx({
    mb: pxToRem(48),
    display: { xs: 'none', lg: 'block' },
    width: pxToRem(88),
  }),
);

type MenuFooterProps = {
  dispatchLogoutUser: () => void;
};

const MenuFooter = (props: MenuFooterProps) => {
  const { dispatchLogoutUser } = props;

  const onLogoutUser = () => {
    dispatchLogoutUser();
  };

  return (
    <FooterStyle data-e2e="footer-section">
      <AppNameStyle variant="subtitle1">{APP_NAME}</AppNameStyle>
      <AppVersionStyle variant="body2">{APP_VERSION}</AppVersionStyle>
      <SafeLink to={GIT_REPO} target="_blank" variant="caption">
        {CURRENT_VERSION}
      </SafeLink>
      <LogoutButtonStyle
        variant="contained"
        color="error"
        onClick={onLogoutUser}
      >
        Log Out
      </LogoutButtonStyle>
    </FooterStyle>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutUser: () => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(MenuFooter);
