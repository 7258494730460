export const ALL_REGIONS = {
  name: 'All Regions',
  token: 'all',
};
export const SEARCH_LABEL = 'Search';
export const SEARCH_START_TOOLTIP = 'Click to Search';
export const SEARCH_UPDATE_TOOLTIP = 'Click to apply filters';
export const DEFAULT_ROWS_PER_PAGE = 50;
export const GRID_DATE_TIME_FORMAT = 'MMM DD HH:mm:ss z';
export const GRID_DATE_FORMAT = 'ddd, MMM D';
export const GRID_FOOTER_HEIGHT = 52;
export const ROWS_PER_PAGE_OPTIONS = [10, 20, 50];

export const DEFAULT_DATE_FORMAT_WITHOUT_TIME = 'MM/DD/YYYY';
export const DEFAULT_TIME_FORMAT_WITHOUT_DATE = 'h:mm a';
