import React, { useState } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { styled, Link as MuiLink, Theme } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import { FONT_PRIMARY, pxToRem } from 'components/theme/typography';
import { isAbsoluteUrl } from 'lib/pathUtils';
import RedirectWarning from 'components/RedirectWarning/RedirectWarning';
import useSafeLink from './useSafeLink';

interface StyledLinkProps {
  variant: TypographyVariant;
}

const linkStyles = ({
  theme,
  variant,
}: {
  theme: Theme;
  variant: TypographyVariant;
}) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  lineHeight: theme.typography[variant].lineHeight || 1.5,
  fontSize: theme.typography[variant].fontSize || pxToRem(16),
  fontFamily: theme.typography[variant].fontFamily || FONT_PRIMARY,
  cursor: 'pointer',
});

const StyledMuiLink = styled(MuiLink)<StyledLinkProps>(linkStyles);
const StyledLink = styled(Link)<StyledLinkProps>(linkStyles);

type SafeLinkProps = LinkProps & {
  variant?: TypographyVariant;
  target?: string;
};

const SafeLink = ({
  variant = 'body1',
  to,
  target,
  children,
  ...props
}: SafeLinkProps) => {
  const [openRedirectWarning, setOpenRedirectWarning] = useState(false);
  const path = String(to);
  const normalizedPath = path.replace(/^\/+/, '/');

  const {
    isMissingProtocol,
    isRestrictedUrl,
    securityProps,
    hasInvalidCoordinates,
  } = useSafeLink(normalizedPath, target);

  if (
    normalizedPath.startsWith('https://www.google.com/maps') &&
    hasInvalidCoordinates
  ) {
    throw new Error(`Invalid Google Maps coordinates in URL: "${path}".`);
  }

  if (isMissingProtocol) {
    throw new Error(
      `SafeLink requires all absolute links to start with a secure protocol.`,
    );
  }

  if (isRestrictedUrl) {
    throw new Error(
      `SafeLink does not support non-whitelisted domains. Received: "${path}". Please use a relative path or an approved domain.`,
    );
  }

  if (isAbsoluteUrl(normalizedPath)) {
    const handleExternalLinkClick = (
      e: React.MouseEvent<HTMLAnchorElement>,
    ) => {
      e.preventDefault();
      setOpenRedirectWarning(true);
    };

    return (
      <>
        <RedirectWarning
          open={openRedirectWarning}
          setOpen={setOpenRedirectWarning}
          href={normalizedPath}
          securityProps={securityProps}
        />
        <StyledMuiLink
          data-safeLink // to be able to easily identify whether or not a link has been converted to use SafeLink
          onClick={handleExternalLinkClick}
          href={normalizedPath}
          variant={variant}
          {...securityProps}
          {...props}
        >
          {children}
        </StyledMuiLink>
      </>
    );
  }

  return (
    <StyledLink
      data-safeLink
      to={normalizedPath}
      variant={variant}
      {...securityProps}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default SafeLink;
