import {
  isAbsoluteUrl,
  isValidLatLong,
  isWhitelistedDomain,
} from 'lib/pathUtils';
import { useMemo } from 'react';
import { LinkProps } from '@mui/material';

const useSafeLink = (path: string, target?: LinkProps['target']) => {
  const isGoogleMapsUrl = path.startsWith('https://www.google.com/maps');
  const hasInvalidCoordinates = isGoogleMapsUrl && !isValidLatLong(path);

  const isMissingProtocol = !path.startsWith('https://') && isAbsoluteUrl(path);

  const isRestrictedUrl =
    isAbsoluteUrl(path) && !isWhitelistedDomain(path) && !isGoogleMapsUrl;

  const securityProps = useMemo(
    () =>
      target === '_blank'
        ? {
            rel: 'noopener noreferrer',
            target: '_blank',
          }
        : {},
    [target],
  );

  return {
    isMissingProtocol,
    isRestrictedUrl,
    hasInvalidCoordinates,
    securityProps,
  };
};

export default useSafeLink;
