import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onUpdateMessageConfig } from 'reduxStore/actions/messages';
import { logoutUser } from 'reduxStore/actions/session';
import { AppState } from 'reduxStore/types';

export const INACTIVE_TIMEOUT = 30 * 60 * 1000; // 30 minutes
export const CHECK_INTERVAL = 10 * 60 * 1000; // 10 minutes

const EVENTS = [
  'mousedown',
  'keydown',
  'mousemove',
  'wheel',
  'touchstart',
  'touchmove',
] as const;

const useSessionManagement = (): void => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(
    (state: AppState) => state?.session?.isSignedIn,
  );
  const lastActivityRef = useRef(Date.now());
  const intervalRef = useRef<NodeJS.Timeout>();

  const handleLogout = useCallback(() => {
    dispatch(logoutUser());
    dispatch(
      onUpdateMessageConfig({
        open: true,
        message: "You've been logged out due to inactivity",
        position: { vertical: 'top', horizontal: 'center' },
        alertSeverity: 'info',
        status: '',
        requestId: '',
      }),
    );
  }, [dispatch]);

  const resetTimer = useCallback(() => {
    lastActivityRef.current = Date.now();
  }, []);

  const checkInactivity = useCallback(() => {
    if (isSignedIn && Date.now() - lastActivityRef.current > INACTIVE_TIMEOUT) {
      handleLogout();
    }
  }, [isSignedIn, handleLogout]);

  useEffect(() => {
    if (!isSignedIn) {
      return undefined;
    }

    lastActivityRef.current = Date.now();

    EVENTS.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    intervalRef.current = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => {
      EVENTS.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isSignedIn, resetTimer, checkInactivity]);
};

export default useSessionManagement;
