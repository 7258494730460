/* This is a copy over file from client/lib/api
 * some changes are:
 * 1. data returned is the response itself insead of res.data (as admintool did)
 * this is to make axios api call work with typescript (it assumes the return value as AxiosPromise<any>)
 *  * 2. error object being like:
 * { message: 'unable to fulfill...', status: error_code, details: [an array of error objects if any], description: simplified error message to display}
 */

import qs from 'qs';
import { pickBy } from 'lodash';
import moment from 'moment';
import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { DEFAULT_CONTENT_TYPE } from 'constants/app';
import { LP_TOKEN } from 'constants/localStorage';
import { PRODUCTION } from 'constants/settings';
import { logout } from 'lib/auth';
import { underscoreToCamelcase } from 'lib/generalUtils';

const { API_URL } = process.env;
// Cache all GET requests.
const _GET_CACHE = {};
let TOKEN = localStorage.getItem(LP_TOKEN) as string;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      console.error(`Auth token expired: ${error}`);
      logout();
    }
    return Promise.reject(error);
  },
);

export const setToken = (token: string): void => {
  TOKEN = token;
};

export class CustomError extends Error {
  requestId: string; // or any other type
}

function createApiResponseError(error, method, path) {
  let errorData = error.response?.data;

  // When an endpoint downloads a file we expect the response of the endpoint to be an ArrayBuffer.
  // However, when the request fails the errors are returned as JSON.
  if (errorData instanceof ArrayBuffer) {
    errorData = JSON.parse(new TextDecoder().decode(errorData));
  }

  const msgBase = `Unable to fulfill: ${method} ${path} `;
  let msgContent = '';

  if (errorData) {
    if (errorData.error) {
      msgContent = errorData.error;
    } else if (
      errorData.errors &&
      Array.isArray(errorData.errors) &&
      errorData.errors.length
    ) {
      msgContent = errorData.errors[0].title || errorData.errors[0].detail;
    } else {
      msgContent = error.response?.statusText;
    }
  }

  const err = new CustomError(`${msgBase}${msgContent}`);
  // @ts-expect-error add status to error message
  err.status = error.response?.status || error.status || 444;
  err.requestId = error.response?.headers['x-request-id'];

  // @ts-expect-error add detail to error message
  err.details = [];
  if (errorData?.error) {
    // @ts-expect-error add detail to error message
    err.detail = errorData.error;
  } else if (errorData?.errors) {
    // @ts-expect-error add detail to error message
    err.details = errorData.errors;
  }

  // add message for amplitude logging event
  const { status } = error.response;
  // @ts-expect-error add log message to error object
  err.logMessage = `status=${status}, ${msgContent}`;

  return err;
}

/**
 * Validates if a Token is still valid or not.
 * @param token
 */
const isTokenExpired = (token: string): boolean => {
  // Return "true" if there is NO token.
  if (!token) {
    return true;
  }

  // Get the token expiration Epoch (Unix time) timestamp.
  const tokenExpEpochTimestamp = JSON.parse(
    Buffer.from(token.split('.')[1], 'base64').toString(),
  )?.exp;

  // Return "true" if there is no value for the token expiration Epoch.
  if (!tokenExpEpochTimestamp) {
    return true;
  }

  // Convert from Epoch to human readable date.
  const expDate = moment.unix(tokenExpEpochTimestamp);

  // Define the token is expired if the current date is not lower than the expiration date.
  return !moment().isBefore(expDate);
};

async function callAxios(
  path,
  method,
  body?,
  contentType = DEFAULT_CONTENT_TYPE,
  requiresToken = true,
  responseType: ResponseType = 'json',
) {
  const request: AxiosRequestConfig = {
    url: path,
    baseURL: API_URL,
    responseType,
    method,
    headers: {
      authorization: '',
    },
    data: null,
  };

  if (TOKEN) {
    request.headers.authorization = `Token token=${TOKEN}`;
  }

  if (body) {
    request.data = body;
  }
  request.headers['Content-Type'] = contentType;

  // Logout the user if the API requires a TOKEN but it is expired.
  if (requiresToken && isTokenExpired(TOKEN)) {
    logout();
    console.error('Auth token expired');
    const error = new Error('Auth token expired');
    // @ts-expect-error add status to error message
    error.status = 401;
    // @ts-expect-error add requestId to error message
    error.requestId = '';
    throw createApiResponseError(error, method, path);
  }

  try {
    const res = await axios(request);
    return underscoreToCamelcase(res);
  } catch (error) {
    throw createApiResponseError(error, method, path);
  }
}

function createPath(path, params) {
  let newPath = path;
  const validParams = pickBy(params, (val) => typeof val !== 'undefined');

  if (validParams && Object.keys(validParams).length) {
    newPath += `?${qs.stringify(validParams)}`;
  }
  return newPath;
}

function clearRelatedCache(path) {
  const pathArr = path.split('/');
  const lookUpKey = pathArr.slice(0, 3).join('/');
  Object.keys(_GET_CACHE).forEach((k) => {
    if (k.includes(TOKEN) && k.includes(lookUpKey)) {
      delete _GET_CACHE[k];
    }
  });
}

const api = {
  get(
    path: string,
    params: unknown,
    requiresToken = true,
  ): Promise<AxiosResponse> {
    const method = 'GET';

    if (!TOKEN) {
      setToken(localStorage.getItem(LP_TOKEN) as string);
    }

    const fullPath = createPath(path, params);
    return callAxios(fullPath, method, undefined, undefined, requiresToken);
  },

  post(
    path: string,
    body: any,
    method = 'POST',
    contentType = DEFAULT_CONTENT_TYPE,
    requiresToken = true,
  ): Promise<AxiosResponse> {
    clearRelatedCache(path);

    // Call with the raw body if it is formdata
    if (body instanceof FormData) {
      return callAxios(path, method, body, contentType, requiresToken);
    }

    let requestBody = pickBy(
      body,
      (val) => val !== null && typeof val !== 'undefined',
    ) as unknown as string;

    if (requestBody && Object.keys(requestBody).length) {
      requestBody = JSON.stringify(requestBody);
    } else {
      requestBody = '';
    }
    return callAxios(path, method, requestBody, contentType, requiresToken);
  },

  patch(
    path: string,
    params: unknown,
    method = 'PATCH',
    contentType = DEFAULT_CONTENT_TYPE,
    requiresToken = true,
  ): Promise<AxiosResponse> {
    clearRelatedCache(path);
    return api.post(path, params, method, contentType, requiresToken);
  },

  put(
    path: string,
    params: unknown,
    method = 'PUT',
    requiresToken = true,
  ): Promise<AxiosResponse> {
    clearRelatedCache(path);
    return api.post(path, params, method, DEFAULT_CONTENT_TYPE, requiresToken);
  },

  delete(
    path: string,
    params: unknown,
    method = 'DELETE',
    requiresToken = true,
  ): Promise<AxiosResponse> {
    clearRelatedCache(path);
    return api.post(path, params, method, DEFAULT_CONTENT_TYPE, requiresToken);
  },

  download: async (
    path: string,
    params: unknown,
    contentType: string,
    name: string,
    requiresToken = true,
  ) => {
    const fullPath = createPath(path, params);
    const res = await callAxios(
      fullPath,
      'GET',
      undefined,
      contentType,
      requiresToken,
      'arraybuffer',
    );

    const blob = new Blob([res.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode!.removeChild(link);
  },
};

if (!PRODUCTION) {
  // @ts-expect-error add api to window
  window.api = api;
}

export default api;
