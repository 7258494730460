import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useCallback, useMemo } from 'react';

const useBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const getDevice = useCallback(() => {
    if (isDesktop) {
      return 'desktop';
    }
    if (isTablet) {
      return 'tablet';
    }
    return 'mobile';
  }, [isDesktop, isTablet]);

  const device = useMemo(() => getDevice(), [getDevice]);

  return { isMobile, isTablet, isDesktop, device };
};

export default useBreakpoint;
