import {
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Stack,
  styled,
} from '@mui/material';
import React from 'react';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
}));

type RedirectWarningProps = DialogProps & {
  setOpen: (val: boolean) => void;
  href: string;
  securityProps?: {
    rel?: string;
    target?: string;
  };
};

const RedirectWarning = ({
  open,
  setOpen,
  href,
  securityProps,
}: RedirectWarningProps) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    window.open(href, securityProps?.target || '_self', securityProps?.rel);
    setOpen(false);
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="redirect-warning-title"
      aria-describedby="redirect-warning-description"
    >
      <DialogTitle id="redirect-warning-title">Warning</DialogTitle>
      <DialogContent>
        <Stack gap={2} alignItems="center">
          <Typography variant="body1" id="redirect-warning-description">
            You are about to leave LP Dashboard and access an external website:
          </Typography>
          <Typography color="error">{href}</Typography>
          <Typography>
            This action introduces potential security and compliance risks,
            including a risk of phishing and malware.
          </Typography>
          <Typography>
            Proceed only if you are certain of the website&apos;s legitimacy and
            have confirmed it meets Lime&apos;s security guidelines.
          </Typography>
        </Stack>
      </DialogContent>
      <StyledDialogActions>
        <Button
          data-testId="cancelRedirect"
          onClick={handleClose}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          data-testId="confirmRedirect"
          onClick={handleConfirm}
          variant="contained"
        >
          Confirm
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default RedirectWarning;
