import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  DateTimePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  DateSelector,
  DateTimeValidator,
  DATE_RANGE_OPTIONS,
  DATE_RANGE_TYPES,
  DATE_TIME_ERRORS,
} from 'types/tasks.d';
import {
  FUTURE_DATE_ERROR,
  INVALID_DATE_ERROR,
  MAX_DATE_ERROR,
  PAST_DATE_ERROR,
} from 'constants/filterOptions';
import useBreakpoint from 'hooks/useBreakpoint';

type CustomDateTimePickerProps = {
  disableFuture?: boolean;
  inputFormat?: string;
  label: string;
  maxDateTime?: DateSelector;
  onChange: (newValue: DateSelector, hasError: boolean) => void;
  rangeType?: DATE_RANGE_OPTIONS;
  value: Date | number | string | null;
};

const dateTimeFormat = 'YYYY/MM/DD hh:mm a';

const CustomDateTimePicker = (props: CustomDateTimePickerProps) => {
  const {
    disableFuture,
    inputFormat = dateTimeFormat,
    rangeType,
    label,
    maxDateTime,
    value,
    onChange,
  } = props;
  const { isMobile, isDesktop } = useBreakpoint();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [helperText, setHelperText] = useState<string | undefined>();

  const handleChange = (newValue: DateSelector) => {
    onChange(newValue, !!errorMessage);
  };

  const handleError = (reason: DateTimeValidator, newValue: DateSelector) => {
    const maxDateTimeFormatted = maxDateTime
      ? moment(maxDateTime).format(dateTimeFormat)
      : null;

    let errorMsg;
    let complement = '';

    if (rangeType === DATE_RANGE_TYPES.start) {
      complement = 'Start ';
    } else if (rangeType === DATE_RANGE_TYPES.end) {
      complement = 'End ';
    }

    switch (reason) {
      case DATE_TIME_ERRORS.disableFuture:
        errorMsg = `${complement}${FUTURE_DATE_ERROR}`;
        break;
      case DATE_TIME_ERRORS.disablePast:
        errorMsg = `${complement}${PAST_DATE_ERROR}`;
        break;
      case DATE_TIME_ERRORS.invalidDate:
        errorMsg = `${complement}${INVALID_DATE_ERROR}`;
        break;
      case DATE_TIME_ERRORS.maxDate:
        errorMsg = maxDateTimeFormatted
          ? `${complement}${MAX_DATE_ERROR.replace('{DATE}', maxDateTimeFormatted)}`
          : '';
        break;
      default:
        errorMsg = undefined;
    }

    setErrorMessage(errorMsg);
    onChange(newValue, !!errorMsg);
  };

  useEffect(() => {
    setHelperText(errorMessage);
  }, [errorMessage]);

  const sharedProps = {
    label,
    value: moment(value),
    format: inputFormat,
    maxDateTime: moment(maxDateTime),
    disableFuture,
    onChange: handleChange,
    onError: handleError,
    slotProps: { textField: { helperText } },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {isDesktop && <DateTimePicker {...sharedProps} />}
      {isMobile && <MobileDateTimePicker {...sharedProps} />}
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
